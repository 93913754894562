<script setup lang="ts">
import { ref, reactive, toRefs, onMounted, shallowRef } from 'vue';
import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-trackplayer';
import 'leaflet.chinatmsproviders'
import { CircleCheck } from '@element-plus/icons-vue'
import { getInfoByOrder, getInfoByTransport } from '@/api/index'
import startIcon from '@/assets/start.png'
import endIcon from '@/assets/end.png'
import train from '@/assets/huoche.png'
import car from '@/assets/car.png'
import { ElLoading, type TabsPaneContext } from 'element-plus';
import 'element-plus/theme-chalk/el-loading.css'
const state = reactive({
  isLeft: false,
  activeName: 'first',
  isHigh: false,
  formInline: {
    user: '',
    region: '',
    time: ''
  },
  orderNo: '',
  startPoint: '北京',
  destination: '昆明',
  startTime: '2024-09-01 14:00:00',
  endTime: '2024-10-23 14:43:00',
  dayNum: 0,
  activities: [{
    content: 'Custom icon',
    timestamp: '2018-04-12 20:46',
    type: 'primary',
    icon: CircleCheck,
  }],
  tileOptions: {
    key: '3892a8b8cc6fa04aa1cb588da0e14ee6',
    minZoom: 3
  },
  transportNo: '',
  curTransportNo: '1',
  curTrackList: [],
  allTrackArray: [],
  consigneeLng: 0,
  consigneeLat: 0,
  deliveryLat: 0,
  deliveryLng: 0,
  curchildOrder: '主运单',
  detailBox: false,
  orderStatus:'',
  isTrain:false
})
interface PassingPoint {
  type: string;
  icon: string;
  pointName: string;
  reportTime: string;
  statusType:string;
  pointType:string;
}
type GroupedByCity = {
  [city: string]: PassingPoint[];
};
interface TransportTabItem {
  transportNo: string;
  passingPointList: PassingPoint[];
  trajectoryList: Object;
  transportMode:string;
  carNo:string;
  trainNo:string;
  orderStatus:string
}
interface OrderTabItem {
  transportNo: string;
  hasSubOrder: boolean;
  passingPointList: PassingPoint[];
  trajectoryList: Object;
}
let transportTabs = shallowRef<TransportTabItem[]>([]);
let timelineList = shallowRef<GroupedByCity[]>([]);
let orderTabs = shallowRef<OrderTabItem[]>([])
// let timelineList = ref([])

const standardLayer = L.layerGroup([
  L.tileLayer.chinaProvider('TianDiTu.Normal.Map', state.tileOptions),
  L.tileLayer.chinaProvider('TianDiTu.Normal.Annotion', state.tileOptions)
])
const imageLayer = L.layerGroup([
  L.tileLayer.chinaProvider('TianDiTu.Satellite.Map', state.tileOptions),
])
let loading = ref()
onMounted(() => {
  loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  initMap()
  if (location.search) {
    // console.log(location.search.split('?')[1].split('=')[0], location.search.split('?')[1].split('=')[1])
    let params = location.search.split('?')[1]
    // if (params.split('=')[0] == 'transportNo') {
    //   state.transportNo = params.split('=')[1]
    //   getTransportData()
    // } else if (params.split('=')[0] == 'orderNo') {
    //   state.orderNo = params.split('=')[1]
    //   getOrderData()
    // }
    if (params.split('=')[0] == 'transportNo') {
      state.transportNo = params.split('=')[1].split('&')[0]
      getTransportData()
    } else if (params.split('=')[0] == 'orderNo') {
      state.orderNo = params.split('=')[1].split('&')[0]
      getOrderData()
    }
  }

})

//初始化地图
const initMap = () => {
  map.value = L.map('map', {
    center: [39.9, 116.4],
    zoom: 4,
    attributionControl: true,
    zoomControl: true,
    boxZoom: false,
    keyboard: false,
    preferCanvas: true,
    layers: [
      standardLayer
    ]
  })

  // L.control.zoom({
  //   position: 'bottomright'
  // }).addTo(map.value)

  let baseMaps = {
    "标准地图": standardLayer,
    "影像地图": imageLayer
  };
  let overlayMaps = {
    "显示路网": L.layerGroup([
      L.tileLayer.chinaProvider('TianDiTu.Satellite.Annotion', state.tileOptions)
    ])
  };
  // L.control.layers(baseMaps, overlayMaps).addTo(map.value);
}
const map = ref()
let LGroup = L.layerGroup()
let trackCar: any = null
//地图轨迹整体
const InitTrack = (data: any) => {
  let point = data.filter((it: any) => (it.lon || it.lat)).map((it: any) => {
    return L.latLng(it.lat, it.lon)
  })
  let polyline = L.polyline(point, { color: '#666' });

  LGroup.value.addLayer(polyline).addTo(map.value)
  map.value.fitBounds(polyline.getBounds());
}
//局部轨迹
const ChildTrack = (data: any) => {
  let point = data.filter((it: any) => (it.lon || it.lat)).map((it: any) => {
    return L.latLng(it.lat, it.lon)
  })
  let polyline = L.polyline(point, { color: '#666' });
  LGroup.value.addLayer(polyline).addTo(map.value)

  let pointGone = data.filter((it: any) => (it.reportTime)).map((it: any) => {
    return L.latLng(it.lat, it.lon)
  })
  if(!pointGone.length){
    pointGone.unshift(point[1])
    pointGone.unshift(point[0])
  }
  trackPlay(pointGone)
}

//轨迹播放
const trackPlay = (path: any) => {
  // map.value.removeLayer(trackCar)
  // trackCar = null
  if (trackCar) {
    // trackCar = null
    trackCar.remove()
  }

  let markerIcon = L.icon({
    iconSize: [45, 45],
    iconUrl: state.isTrain?train:car
  });

  trackCar = new L.TrackPlayer(path,
    {
      markerIcon,
      speed: 250000, // 播放速度
      weight: 6, // 轨迹线宽度
      passedLineColor: 'green', // 已行驶轨迹部分的颜色
      notPassedLineColor: '#666', // 未行驶轨迹部分的颜色
      panTo: false, // 地图跟随移动
      markerRotation: true, // 是否开启marker的旋转
      // markerRotationOffset: 180
    }
  ).addTo(map.value)
  trackCar.start();
}


//地图图层销毁
const clear = () => {
  map.value.removeLayer(LGroup)
}

//起终点
const InitPoints = (startLng: number, startLat: number, endLng: number, endLat: number) => {
  let pointStart = L.latLng(startLng, startLat)
  let pointEnd = L.latLng(endLng, endLat)
  let markerStart:any;
  let markerEnd:any;
  if(pointStart){
    markerStart = L.marker([pointStart.lng, pointStart.lat], {
      icon: L.icon({
        iconUrl: startIcon,
        iconSize: [30, 30],
      })
    })
  }
  if(pointEnd){
    markerEnd = L.marker([pointEnd.lng, pointEnd.lat], {
      icon: L.icon({
        iconUrl: endIcon,
        iconSize: [30, 30],
      })
    })
  }
  
  // LGroup.value = L.layerGroup([markerStart, markerEnd]).addTo(map.value)
  // LGroup.value.addLayer(markerStart).addTo(map.value)
  // LGroup.value.addLayer(markerEnd).addTo(map.value)
  if(markerStart && markerEnd){
    LGroup.value = L.layerGroup([markerStart,markerEnd]).addTo(map.value)
  }else if(markerStart){
    LGroup.value = L.layerGroup([markerStart]).addTo(map.value)
  }else if(markerEnd){
    LGroup.value = L.layerGroup([markerEnd]).addTo(map.value)
  }
}

//处理途经点函数
const handlePassList = (timeArr: any) => {
  if (!timeArr) {
    return
  }
  timeArr.forEach((val: any) => {
    val.icon = CircleCheck
    val.type = 'primary'
  })
  let result = timeArr.reduce((acc: any, item: any) => {
    if (!acc[item.pointName]) {
      acc[item.pointName] = [];
    }
    acc[item.pointName].push(item);
    return acc;
  }, {});
  return result
}

//通过订单号获取的数据
const getOrderData = () => {
  getInfoByOrder({ orderNo: btoa(state.orderNo) }).then((res: any) => {
    // console.log(res.data);
    if (res.code != 200) { 
      ElMessage.error(res.msg)
      loading.close()
      return 
    }
    loading.close()
    state.orderNo = res.data.orderNo
    state.orderStatus = res.data.orderStatus
    state.startPoint = res.data.deliveryCity
    state.destination = res.data.consigneeCity
    state.startTime = res.data.actualDepartTime
    state.endTime = res.data.actualSignTime
    if (res.data.actualSignTime && res.data.actualDepartTime) {
      let day:number = ((new Date(res.data.actualSignTime).getTime() - new Date(res.data.actualDepartTime).getTime()) / (1000 * 60 * 60 * 24)).toFixed(1)
      // console.log(state.dayNum);
      if(day<0.1){
        state.dayNum = 0.1
      }else{
        state.dayNum = day
      }
    }
    //1.主订单无子订单，主运单无子运单
    if (!res.data.hasSubOrder && !res.data.transportVo.hasSubOrder) {
      state.allTrackArray = []
      // state.transportList = res.data.transportList
      console.log('1.途经点信息', res.data.transportVo.passingPointList);
      console.log('1.轨迹信息', res.data.transportVo.trajectoryList);
      orderTabs.value = [res.data.transportVo]
      transportTabs.value = [res.data.transportVo]
      if(res.data.transportVo.transportMode == '60'){
        state.isTrain = true
      }else{
        state.isTrain = false
      }
      state.curchildOrder = res.data.transportVo.transportNo
      state.curTransportNo = res.data.transportVo.transportNo
      let trackData = res.data.transportVo.trajectoryList
      state.allTrackArray = res.data.transportVo.trajectoryList
      let timeArr = res.data.transportVo.passingPointList
      timelineList.value = handlePassList(timeArr)
      state.deliveryLng = res.data.transportVo.deliveryLng
      state.deliveryLat = res.data.transportVo.deliveryLat
      state.consigneeLat = res.data.transportVo.consigneeLat
      state.consigneeLng = res.data.transportVo.consigneeLng
      InitPoints(state.deliveryLng, state.deliveryLat, state.consigneeLng, state.consigneeLat)
      InitTrack(state.allTrackArray)
      ChildTrack(trackData)
    }
    //2.主订单无子订单，主运单有子运单
    if (!res.data.hasSubOrder && res.data.transportVo.hasSubOrder) {
      state.allTrackArray = []
      // console.log('2.子运单列表', res.data.transportVo.transportList);
      orderTabs.value = [res.data.transportVo]
      state.curchildOrder = res.data.transportVo.transportNo

      let dataList = res.data.transportVo.transportList
      dataList.forEach((ele: any) => {
        state.allTrackArray = state.allTrackArray.concat(ele.trajectoryList)
      });
      transportTabs.value = dataList
      state.curTransportNo = dataList[0].transportNo
      if(dataList[0].transportMode == '60'){
        state.isTrain = true
      }else{
        state.isTrain = false
      }

      let timeArr = dataList[0].passingPointList
      timelineList.value = handlePassList(timeArr)
      // console.log(timelineList.value);

      state.deliveryLng = res.data.transportVo.deliveryLng
      state.deliveryLat = res.data.transportVo.deliveryLat
      state.consigneeLat = res.data.transportVo.consigneeLat
      state.consigneeLng = res.data.transportVo.consigneeLng
      InitPoints(state.deliveryLng, state.deliveryLat, state.consigneeLng, state.consigneeLat)
      InitTrack(state.allTrackArray)
      ChildTrack(dataList[0].trajectoryList)
    }
    //3.主订单有子订单，
    if (res.data.hasSubOrder) {
      state.allTrackArray = []
      console.log('3.子订单列表', res.data.transportList);
      orderTabs.value = res.data.transportList.filter((item: any) => item !== null);
      console.log(orderTabs.value);

      state.curchildOrder = res.data.transportList[0].transportNo
      state.deliveryLng = res.data.deliveryLng
      state.deliveryLat = res.data.deliveryLat
      state.consigneeLat = res.data.consigneeLat
      state.consigneeLng = res.data.consigneeLng
      InitPoints(state.deliveryLng, state.deliveryLat, state.consigneeLng, state.consigneeLat)
      let childOrderArr = res.data.transportList
      childOrderArr.forEach((val: any) => {
        if (val && !val.hasSubOrder) {
          //子订单无子运单
          // console.log('4.子订单无子运单', val);
          if (val.trajectoryList) {
            state.allTrackArray = state.allTrackArray.concat(val.trajectoryList)
          }
        } else if (val && val.hasSubOrder) {
          //子订单有子运单
          val.transportList.forEach((item: any) => {
            state.allTrackArray = state.allTrackArray.concat(item.trajectoryList)
          })
        }
      })

      if (state.allTrackArray && state.allTrackArray.length) {
        InitTrack(state.allTrackArray)
      }

      if (res.data.transportList[0].hasSubOrder && res.data.transportList[0].transportList) {
        transportTabs.value = res.data.transportList[0].transportList
        state.curTransportNo = res.data.transportList[0].transportList[0].transportNo
        let timeArr = res.data.transportList[0].transportList[0].passingPointList
        timelineList.value = handlePassList(timeArr)
        if(res.data.transportList[0].transportList[0].transportMode == '60'){
            state.isTrain = true
          }else{
            state.isTrain = false
          }
        if (res.data.transportList[0].transportList) {
          ChildTrack(res.data.transportList[0].transportList[0].trajectoryList)
        }
      } else if (!res.data.transportList[0].hasSubOrder && !res.data.transportList[0].transportList) {
        transportTabs.value = [res.data.transportList[0]]
        state.curTransportNo = res.data.transportList[0].transportNo
        let timeArr = res.data.transportList[0].passingPointList
        timelineList.value = handlePassList(timeArr)
        if(res.data.transportList[0].transportMode == '60'){
            state.isTrain = true
          }else{
            state.isTrain = false
          }
        if (res.data.transportList[0].trajectoryList) {
          ChildTrack(res.data.transportList[0].trajectoryList)
        }
      }
    }
  })
}
const getTransportData = () => {
  getInfoByTransport({ transportNo: btoa(state.transportNo) }).then((res: any) => {
    // console.log(res);
    if (res.code != 200) { 
      ElMessage.error(res.msg)
      loading.close()
      return 
    }
    loading.close()
    state.orderNo = res.data.orderNo
    state.startPoint = res.data.deliveryCity
    state.destination = res.data.consigneeCity
    state.startTime = res.data.actualDepartTime
    state.endTime = res.data.actualSignTime
    if (res.data.actualSignTime && res.data.actualDepartTime) {
      let day:number = ((new Date(res.data.actualSignTime).getTime() - new Date(res.data.actualDepartTime).getTime()) / (1000 * 60 * 60 * 24)).toFixed(1)
      if(day<0.1){
        state.dayNum = 0.1
      }else{
        state.dayNum = day
      }
    }
    state.deliveryLng = res.data.deliveryLng
    state.deliveryLat = res.data.deliveryLat
    state.consigneeLat = res.data.consigneeLat
    state.consigneeLng = res.data.consigneeLng
    InitPoints(state.deliveryLng, state.deliveryLat, state.consigneeLng, state.consigneeLat)
    orderTabs.value = [res.data]
    state.curchildOrder = res.data.transportNo
    if (!res.data.hasSubOrder) {
      console.log('11.无子运单');

      let trackData = res.data.trajectoryList
      let timeArr = res.data.passingPointList
      timelineList.value = handlePassList(timeArr)
      if(res.data.transportMode == '60'){
            state.isTrain = true
          }else{
            state.isTrain = false
          }
      InitTrack(trackData)
      ChildTrack(trackData)
      transportTabs.value = [res.data]
      state.curTransportNo = res.data.transportNo
    } else {
      console.log('22.有子运单');
      let dataList = res.data.transportList
      dataList.forEach((ele: any) => {
        state.allTrackArray = state.allTrackArray.concat(ele.trajectoryList)
      });
      transportTabs.value = dataList
      state.curTransportNo = dataList[0].transportNo

      let timeArr = dataList[0].passingPointList
      timelineList.value = handlePassList(timeArr)
      // console.log(timelineList.value);
      if(dataList[0].transportMode == '60'){
            state.isTrain = true
          }else{
            state.isTrain = false
          }
      InitTrack(state.allTrackArray)
      ChildTrack(dataList[0].trajectoryList)
    }
  })
}

const toUnfold = () => {
  state.isLeft = !state.isLeft
}
const handleClick = (tab: TabsPaneContext, event: Event) => {
  // console.log(tab, event)
}
const handleClickOrder = (tab: TabsPaneContext, event: Event) => {
  state.curchildOrder = String(tab.paneName)
  let obj: any = orderTabs.value.find(val => val.transportNo == tab.paneName)
  if(obj.transportMode == '60'){
    state.isTrain = true
  }else{
    state.isTrain = false
  }
  if (!obj.hasSubOrder) {
    //子订单无子运单
    console.log('4.子订单无子运单');
    transportTabs.value = [obj]
    state.curTransportNo = obj.transportNo
    let timeArr = obj.passingPointList
    timelineList.value = handlePassList(timeArr)
    if (obj.trajectoryList) {
      ChildTrack(obj.trajectoryList)
    }
  } else {
    //子订单有子运单
    console.log('5.子订单有子运单');
    transportTabs.value = obj.transportList
    state.curTransportNo = obj.transportList[0].transportNo
    let timeArr = obj.transportList[0].passingPointList
    timelineList.value = handlePassList(timeArr)
    ChildTrack(obj.transportList[0].trajectoryList)
  }
}
const onSubmit = () => {
  console.log('submit!')
}
const highSearch = () => {
  state.isHigh = !state.isHigh
}

const transportChange = (e: any) => {
  let obj: any = transportTabs.value.find(val => val.transportNo == e)
  console.log(e);
  if(e.transportMode == '60'){
    state.isTrain = true
  }else{
    state.isTrain = false
  }

  // timelineList.value = arr[0].passingPointList
  let timeArr = obj.passingPointList
  timeArr.forEach((val: any) => {
    val.icon = CircleCheck
    val.type = 'primary'
  })
  timelineList.value = timeArr.reduce((acc: any, item: any) => {
    if (!acc[item.pointName]) {
      acc[item.pointName] = [];
    }
    acc[item.pointName].push(item);
    return acc;
  }, {});

  
  ChildTrack(obj.trajectoryList)
}

const showPointType = (e:string) => {
  let type = ''
  switch(e){
    case '0':
      type = '发站'
      break;
    case '1':
      type = '到站'
      break;
    case '2':
      type = '口岸站'
      break;
    case '3':
      type = '途经站'
      break;
    case '9':
      type = '其他'
      break;
    default:
      break;
  }
  return type
}

const showTranportStatus = (e:string) => {
  let type = ''
  switch(e){
    case '1':
      type = '未生效'
      break;
    case '30':
      type = '已生效'
      break;
    case '35':
      type = '已配载'
      break;
    case '37':
      type = '待装货'
      break;
    case '40':
      type = '已发运'
      break;
    case '45':
      type = '已运抵'
      break;
    case '55':
      type = '已签收'
      break;
    case '60':
      type = '已回单'
      break;
    case '80':
      type = '已关闭'
      break;
    default:
      break;
  }
  return type
}

const showOrderStatus = (e:string) => {
  let type = ''
  switch(e){
    case '1':
      type = '未生效'
      break;
    case '5':
      type = '已生效'
      break;
    case '10':
      type = '已下发'
      break;
    case '15':
      type = '生成波次'
      break;
    case '20':
      type = '已分配'
      break;
    case '25':
      type = '拣货'
      break;
    case '30':
      type = '播种'
      break;
    case '35':
      type = '复核'
      break;
    case '40':
      type = '包装'
      break;
    case '45':
      type = '已配载'
      break;
    case '50':
      type = '已发运'
      break;
    case '55':
      type = '已签收'
      break;
    case '60':
      type = '已回单'
      break;
    case '65':
      type = '部分收货'
      break;
    case '70':
      type = '收货完成'
      break;
    case '100':
      type = '已关闭'
      break;
    default:
      break;
  }
  return type
}

const spreadDetail = () => {
  state.detailBox = !state.detailBox
}

const { detailBox, isLeft, activeName, formInline, isHigh, orderNo, startPoint, destination, startTime, endTime, dayNum, activities, curTransportNo, curchildOrder ,orderStatus} = toRefs(state);

</script>

<template>
  <div class="container">
    <div id="map"></div>
    <div class="detail" @click="spreadDetail">
      <el-icon :size="31">
        <DocumentCopy />
      </el-icon>
    </div>
    <div class="detailBlock" v-if="detailBox">
      <el-scrollbar height="80vh">
        <el-card class="box-card">
          <div class="boxNo">
              <div>{{ orderNo }}</div>
              <div><el-tag>{{ showOrderStatus(orderStatus) }}</el-tag></div>
            </div>
          <div class="city">
            <div>{{ startPoint }}</div>
            <div><img src="../assets/daoda.png" alt=""></div>
            <div>{{ destination }}</div>
          </div>
          <div class="cardTime">
            <div>{{ startTime }}</div>
            <!-- <div>{{ dayNum }}天</div> -->
            <el-tag type="primary" effect="dark" round v-if="dayNum != 0">
              {{ dayNum }} 天
            </el-tag>
            <div>{{ endTime }}</div>
          </div>
        </el-card>
        <el-tabs class="tabLists" v-model="curchildOrder" type="border-card" @tab-click="handleClickOrder">
          <el-tab-pane :label="'主运单' + (orderIndex + 1)" :name="orderItem.transportNo"
            v-for="(orderItem, orderIndex) in orderTabs" :key="orderIndex">
            <el-scrollbar height="calc(80vh - 175px)">
              <el-tabs v-model="curTransportNo" class="demo-tabs" @tab-change="transportChange">
                <!-- <el-tab-pane :label="transportItem.transportNo" :name="transportItem.transportNo" v-for="(transportItem, index) in transportTabs" :key="index"> -->
                <el-tab-pane :label="'子运单' + (transportIndex + 1)" :name="transportItem.transportNo"
                  v-for="(transportItem, transportIndex) in transportTabs" :key="transportIndex">
                  <div>主运单号：{{ orderItem.transportNo }}</div>
                  <div>子运单号：{{ transportItem.transportNo }}</div>
                  <div>
                    <div v-if="transportItem.transportMode=='60'">车次：{{ transportItem.trainNo }}</div>
                    <div v-else>车牌号：{{ transportItem.carNo }}</div>
                  </div>
                  <div>运单状态：<el-tag>{{ showTranportStatus(transportItem.orderStatus) }}</el-tag></div>
                  <el-timeline style="padding: 10px;">
                    <el-timeline-item v-for="(activity, indexChild) in timelineList" :key="indexChild"
                      :hide-timestamp="true" :type="activity[0].type" :icon="activity[0].icon">
                      <el-card shadow="never">
                        <template #header>
                          <div class="card-header">
                              <span v-if="transportItem.transportMode=='60'">{{showPointType(activity[0].pointType)}}：{{ activity[0].pointName }}</span>
                              <span v-else>途经点：{{ activity[0].pointName }}</span>
                            </div>
                        </template>
                        <div>
                            <div>到达：{{ activity[0].reportTime }}</div>
                            <div v-if="activity.length != 1">出发：{{ activity[1].reportTime }}</div>
                          </div>
                          <!-- <div>
                            <div v-if="transportItem.transportMode=='60'">车次：{{ transportItem.trainNo }}</div>
                            <div v-else>车牌号：{{ transportItem.carNo }}</div>
                          </div> -->
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </el-tab-pane>
              </el-tabs>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-scrollbar>
    </div>
  </div>
</template>
<style scoped lang="scss">
.container {
  width: 100vw;
  height: 100vh;

  #map {
    width: 100vw;
    height: 100vh;
  }

  .detail {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 5px #000;
    position: fixed;
    bottom: 24px;
    right: 12px;
    z-index: 999;
    text-align: center;
    padding-top: 7px;
  }

  .detailBlock {
    width: 94vw;
    height: 80vh;
    position: fixed;
    bottom: 80px;
    left: 3vw;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .box-card {

    .boxNo {
        color: #409eff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

    .city {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 15px;
      padding: 10px 0;

      img {
        width: 100px;
        height: 15px;
      }
    }

    .cardTime {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }
  }

  .tabLists {
    margin-top: 10px;

    .demo-tabs {
      padding: 10px;
    }
  }

  ::v-deep .el-form--inline .el-form-item {
    margin-right: 20px;
  }

  ::v-deep .el-card__body {
    padding: 10px;
  }

  ::v-deep .el-timeline-item .el-timeline-item__icon {
    font-size: large;
  }

  ::v-deep .el-card__header {
    padding: 10px;
  }

  ::v-deep .el-tabs--border-card>.el-tabs__content {
    padding: 0;
  }

}
</style>